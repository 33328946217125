import React, { useState } from 'react';
import './esqueci_senha.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/230616_RV_logo_principal_positivo-cor.png';

function EsqueciSenha() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault(); // Não recarrega a página ao submeter
  
    try {
      const response = await fetch(
        `https://rvcurso.com.br/get.php?action=enviarEmailRedefinicaoSenha&email=${email}`
      );
  
      const textResponse = await response.text(); // Obtém a resposta como texto
  
      console.log('Resposta do servidor:', textResponse);
  
      // Dividir os dois objetos JSON retornados
      const jsonParts = textResponse.split('}{').map((part, index, arr) => {
        // Adicionar `{` no início e `}` no final das partes quebradas
        if (index === 0) return `${part}}`;
        if (index === arr.length - 1) return `{${part}`;
        return `{${part}}`;
      });
  
      // Processar cada parte como JSON separadamente
      const data = jsonParts.map((jsonPart) => JSON.parse(jsonPart));
  
      // Verificar o status e exibir a mensagem apropriada
      if (data[0]?.status === 0) {
        setMessage(data[0].message);
      } else if (data[1]?.error) {
        setMessage(data[1].error);
      } else {
        setMessage('Erro desconhecido. Contate o suporte.');
      }
    } catch (error) {
      console.error('Erro ao enviar email de recuperação:', error);
      setMessage('Um erro ocorreu. Tente novamente mais tarde.');
    }
  };
  

  return (
    <div className="page-container login">
      <div className="login-container">
        <form onSubmit={handlePasswordReset}>
          <Link to="/login" className="no-link-style">
            <img src={logo} alt="Logo" className="login-logo" />
          </Link>
          <input
            type="text"
            placeholder="Email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="button" type="submit">
            Enviar email de recuperação
          </button>
          {message && <p className="message">{message}</p>}
        </form>
      </div>
    </div>
  );
}

export default EsqueciSenha;