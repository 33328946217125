import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../../assets/230616_RV_logo_principal_positivo-cor.png';

function RedefinirSenha() {
//   const { token } = useParams(); // Captura o token da URL
  const navigate = useNavigate();
  const [ID, setID] = useState(null); // Armazena o ID extraído do token
  const [newPassword, setNewPassword] = useState('');
//   const [message, setMessage] = useState('');

  const location = useLocation(); // Captura a query string
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Extrai o token da query string
    const params = new URLSearchParams(location.search);
    const tokenValue = params.get('token'); // Captura o valor de "token"
    if (tokenValue) {
      setToken(tokenValue);
    } else {
      setMessage('Token inválido. Contate o suporte.');
    }
  }, [location]);

  const parseTokenToID = (token) => {
    // Simulação de extração do ID a partir do token
    // Exemplo simples: retorna o token como está (ajuste conforme necessário)
    return token; // Ou faça lógica de decodificação aqui
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
  
    if (!token || !newPassword) {
      setMessage('Token ou nova senha estão inválidos.');
      return;
    }
  
    try {
      const response = await fetch(
        `https://rvcurso.com.br/get.php?action=enviarEmailRedefinicaoSenha&ID=${token}&senha=${newPassword}`,
        {
          method: 'GET',
        }
      );
    
      const textResponse = await response.text(); // Captura o texto bruto
      console.log('Resposta do servidor (bruta):', textResponse);
    
      // Dividir objetos JSON consecutivos
      const jsonParts = textResponse.split('}{').map((part, index, array) => {
        if (index === 0) return `${part}}`; // Primeira parte
        if (index === array.length - 1) return `{${part}`; // Última parte
        return `{${part}}`; // Meio
      });
    
      const parsedData = jsonParts.map((jsonString) => JSON.parse(jsonString));
      console.log('Partes analisadas:', parsedData);
    
      // Lógica para processar os objetos JSON
      const successMessage = parsedData.find((obj) => obj.status === "success");
      if (successMessage) {
        setMessage(successMessage.message || 'Senha redefinida com sucesso!');
        setTimeout(() => navigate('/login'), 2000);
      } else {
        const errorMessage = parsedData.find((obj) => obj.error);
        setMessage(errorMessage?.error || 'Erro ao redefinir a senha.');
      }
    } catch (error) {
      console.error('Erro ao redefinir senha:', error);
      setMessage('Um erro ocorreu. Tente novamente mais tarde.');
    }
    
      
  };
  

  return (

        


    <div className="page-container login">
      <div className="login-container">
        <form onSubmit={handlePasswordReset}>
            <Link to="/login" className="no-link-style">
            <img src={logo} alt="Logo" className="login-logo" />
            </Link>
          <input
            type="password"
            placeholder="Nova senha"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button className="button" type="submit">
            Redefinir Senha
          </button>
          {message && <p className="message">{message}</p>}
        </form>
      </div>
    </div>
  );
}

export default RedefinirSenha;