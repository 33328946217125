import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/base/Sidebar/Sidebar';
import ProfileContent from '../../components/perfil/profile_content';
import './perfil.css';

function Perfil() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Verificar se o usuário chegou pela página de login
    const fromLogin = location.state?.fromLogin || false;

    if (fromLogin) {
      const timer = setTimeout(() => {
        navigate('/dashboard');
      }, 5000); // 5 segundos

      // Limpar o temporizador ao desmontar
      return () => clearTimeout(timer);
    }
  }, [location, navigate]);

  return (
    <div className='page-container profile'>
      <div className='menu'>
        <Sidebar />
      </div>
      <div className='content'>
        <ProfileContent />
      </div>
    </div>
  );
}

export default Perfil;
