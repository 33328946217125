import React from 'react';
import './cadastro.css'
import form_cadastro from '../../components/cadastro/form_cadastro';

function Cadastro() {
    return (
        <form_cadastro />
    );
}

export default Cadastro;